
export default function formatDateTimeToPST (dateTimeString,timezone, role) {
    let options;
    if(role <= 2){
         options = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false,            
        }; 
        if(dateTimeString){
            const year = dateTimeString.slice(0, 4);
            const month = dateTimeString.slice(5, 7);
            const day = dateTimeString.slice(8, 10);
            const time = dateTimeString.slice(11, 19);
            const formattedDateTime = `${month}/${day}/${year} ${time}`;        
           
          
            return formattedDateTime;
        }
        
    }else{
        let timezoneName = timezone;
        if(timezone == "AKST"){
            timezoneName = "America/Anchorage";
        }else if(timezone == "AST"){
            timezoneName = "America/Halifax";
        }else if(timezone == "CST"){
            timezoneName = "America/Chicago";
        }else if(timezone == "ChST"){
            timezoneName = "Pacific/Guam";
        }else if(timezone == "EST"){
            timezoneName = "America/New_York";
        }else if(timezone == "HAST") {
            timezoneName = "Pacific/Honolulu";
        }else if(timezone == "MST") {
            timezoneName = "America/Denver";
        }else if(timezone == "PST") {
            timezoneName = "America/Los_Angeles";
        }
         options = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false, // Use 24-hour time format
            timeZone: timezoneName, // PST timezone
        };
        return new Intl.DateTimeFormat('en-US', options).format(new Date(dateTimeString)).replace(',', '');
    }
    
      
    
}